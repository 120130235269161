<template>
    <div class="mb-5 mb-xl-8 card">
        <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
                <span class="card-label fw-bolder fs-3 mb-1">Liste des rapports spécifiques</span>
                <span class="text-muted mt-1 fw-bold fs-7">{{ this.$store.getters.currentSettings.clientName }}</span>
            </h3>
        </div>
        <div class="card-body py-3">
            <div class="table-responsive">
                <table class="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                    <thead>
                        <tr class="fw-bolder text-muted">
                            <th class="min-w-150px">Nom du rapport</th>
                            <th class="min-w-120px">Périmètre</th>
                            <th class="min-w-100px text-end">Téléchargement</th>
                        </tr>
                    </thead>
                    <tbody v-if="!loading">
                        <tr>
                            <td>EST</td>
                            <td>Région</td>
                            <td class="text-end">
                                <a target="_blank" href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_EST.pptx"
                                    ><i class="fas fa-file-powerpoint fs-1"></i
                                ></a>
                                <a target="_blank" href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_EST.xlsx"
                                    ><i class="fas fa-file-excel fs-1 ms-3"></i
                                ></a>
                            </td>
                        </tr>
                        <!-- Fonctions Centrales -->
                        <tr>
                            <td>Fonctions Centrales</td>
                            <td>Région</td>
                            <td class="text-end">
                                <a target="_blank" href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_Fonctions_Centrales.pptx"
                                    ><i class="fas fa-file-powerpoint fs-1"></i
                                ></a>
                                <a target="_blank" href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_Fonctions_Centrales.xlsx"
                                    ><i class="fas fa-file-excel fs-1 ms-3"></i
                                ></a>
                            </td>
                        </tr>
                        <!-- Fonctions Centrales EST -->
                        <tr>
                            <td>Fonctions Centrales EST</td>
                            <td>Région</td>
                            <td class="text-end">
                                <a target="_blank" href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_Fonctions_Centrales_EST.pptx"
                                    ><i class="fas fa-file-powerpoint fs-1"></i
                                ></a>
                                <a target="_blank" href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_Fonctions_Centrales_EST.xlsx"
                                    ><i class="fas fa-file-excel fs-1 ms-3"></i
                                ></a>
                            </td>
                        </tr>
                        <!-- Fonctions Centrales NORD EST -->
                        <tr>
                            <td>Fonctions Centrales NORD EST</td>
                            <td>Région</td>
                            <td class="text-end">
                                <a
                                    target="_blank"
                                    href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_Fonctions_Centrales_NORD_EST.pptx"
                                    ><i class="fas fa-file-powerpoint fs-1"></i
                                ></a>
                                <a
                                    target="_blank"
                                    href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_Fonctions_Centrales_NORD_EST.xlsx"
                                    ><i class="fas fa-file-excel fs-1 ms-3"></i
                                ></a>
                            </td>
                        </tr>
                        <!-- Fonctions Centrales Normandie Paris -->
                        <tr>
                            <td>Fonctions Centrales Normandie Paris</td>
                            <td>Région</td>
                            <td class="text-end">
                                <a
                                    target="_blank"
                                    href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_Fonctions_Centrales_NORMANDIE_PARIS.pptx"
                                    ><i class="fas fa-file-powerpoint fs-1"></i
                                ></a>
                                <a
                                    target="_blank"
                                    href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_Fonctions_Centrales_NORMANDIE_PARIS.xlsx"
                                    ><i class="fas fa-file-excel fs-1 ms-3"></i
                                ></a>
                            </td>
                        </tr>
                        <!-- Fonctions Centrales OUEST -->
                        <tr>
                            <td>Fonctions Centrales OUEST</td>
                            <td>Région</td>
                            <td class="text-end">
                                <a
                                    target="_blank"
                                    href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_Fonctions_Centrales_OUEST.pptx"
                                    ><i class="fas fa-file-powerpoint fs-1"></i
                                ></a>
                                <a
                                    target="_blank"
                                    href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_Fonctions_Centrales_OUEST.xlsx"
                                    ><i class="fas fa-file-excel fs-1 ms-3"></i
                                ></a>
                            </td>
                        </tr>
                        <!-- Fonctions Centrales Paris FC -->
                        <tr>
                            <td>Fonctions Centrales Paris FC</td>
                            <td>Région</td>
                            <td class="text-end">
                                <a
                                    target="_blank"
                                    href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_Fonctions_Centrales_PARIS_FC.pptx"
                                    ><i class="fas fa-file-powerpoint fs-1"></i
                                ></a>
                                <a
                                    target="_blank"
                                    href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_Fonctions_Centrales_PARIS_FC.xlsx"
                                    ><i class="fas fa-file-excel fs-1 ms-3"></i
                                ></a>
                            </td>
                        </tr>

                        <!-- Fonctions Centrales RHONE ALPES AUVERGNE -->
                        <tr>
                            <td>Fonctions Centrales RHONE ALPES AUVERGNE</td>
                            <td>Région</td>
                            <td class="text-end">
                                <a
                                    target="_blank"
                                    href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_Fonctions_Centrales_RHONE_ALPES_AUVERGNE.pptx"
                                    ><i class="fas fa-file-powerpoint fs-1"></i
                                ></a>
                                <a
                                    target="_blank"
                                    href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_Fonctions_Centrales_RHONE_ALPES_AUVERGNE.xlsx"
                                    ><i class="fas fa-file-excel fs-1 ms-3"></i
                                ></a>
                            </td>
                        </tr>
                        <!-- Fonctions Centrales SUD EST -->
                        <tr>
                            <td>Fonctions Centrales SUD EST</td>
                            <td>Région</td>
                            <td class="text-end">
                                <a
                                    target="_blank"
                                    href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_Fonctions_Centrales_SUD_EST.pptx"
                                    ><i class="fas fa-file-powerpoint fs-1"></i
                                ></a>
                                <a
                                    target="_blank"
                                    href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_Fonctions_Centrales_SUD_EST.xlsx"
                                    ><i class="fas fa-file-excel fs-1 ms-3"></i
                                ></a>
                            </td>
                        </tr>
                        <!-- Fonctions Centrales Sud Ouest -->
                        <tr>
                            <td>Fonctions Centrales Sud Ouest</td>
                            <td>Région</td>
                            <td class="text-end">
                                <a
                                    target="_blank"
                                    href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_Fonctions_Centrales_SUD_OUEST.pptx"
                                    ><i class="fas fa-file-powerpoint fs-1"></i
                                ></a>
                                <a
                                    target="_blank"
                                    href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_Fonctions_Centrales_SUD_OUEST.xlsx"
                                    ><i class="fas fa-file-excel fs-1 ms-3"></i
                                ></a>
                            </td>
                        </tr>

                        <!-- Manager -->
                        <tr>
                            <td>Manager</td>
                            <td>Région</td>
                            <td class="text-end">
                                <a target="_blank" href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_Manager.pptx"
                                    ><i class="fas fa-file-powerpoint fs-1"></i
                                ></a>
                                <a target="_blank" href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_Manager.xlsx"
                                    ><i class="fas fa-file-excel fs-1 ms-3"></i
                                ></a>
                            </td>
                        </tr>
                        <!-- NORD EST -->
                        <tr>
                            <td>NORD EST</td>
                            <td>Région</td>
                            <td class="text-end">
                                <a target="_blank" href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_NORD_EST.pptx"
                                    ><i class="fas fa-file-powerpoint fs-1"></i
                                ></a>
                                <a target="_blank" href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_NORD_EST.xlsx"
                                    ><i class="fas fa-file-excel fs-1 ms-3"></i
                                ></a>
                            </td>
                        </tr>
                        <!-- NORMANDIE PARIS -->
                        <tr>
                            <td>NORMANDIE PARIS</td>
                            <td>Région</td>
                            <td class="text-end">
                                <a target="_blank" href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_NORMANDIE_PARIS.pptx"
                                    ><i class="fas fa-file-powerpoint fs-1"></i
                                ></a>
                                <a target="_blank" href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_NORMANDIE_PARIS.xlsx"
                                    ><i class="fas fa-file-excel fs-1 ms-3"></i
                                ></a>
                            </td>
                        </tr>
                        <!-- PARIS OUEST -->
                        <tr>
                            <td>OUEST</td>
                            <td>Région</td>
                            <td class="text-end">
                                <a target="_blank" href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_OUEST.pptx"
                                    ><i class="fas fa-file-powerpoint fs-1"></i
                                ></a>
                                <a target="_blank" href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_OUEST.xlsx"
                                    ><i class="fas fa-file-excel fs-1 ms-3"></i
                                ></a>
                            </td>
                        </tr>
                        <!-- PARIS FC -->
                        <tr>
                            <td>PARIS FC</td>
                            <td>Région</td>
                            <td class="text-end">
                                <a target="_blank" href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_PARIS_FC..pptx"
                                    ><i class="fas fa-file-powerpoint fs-1"></i
                                ></a>
                                <a target="_blank" href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_PARIS_FC..xlsx"
                                    ><i class="fas fa-file-excel fs-1 ms-3"></i
                                ></a>
                            </td>
                        </tr>
                        <!-- RHONE ALPES AUVERGNE -->
                        <tr>
                            <td>RHONE ALPES AUVERGNE</td>
                            <td>Région</td>
                            <td class="text-end">
                                <a target="_blank" href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_RHONE_ALPES_AUVERGNE.pptx"
                                    ><i class="fas fa-file-powerpoint fs-1"></i
                                ></a>
                                <a target="_blank" href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_RHONE_ALPES_AUVERGNE.xlsx"
                                    ><i class="fas fa-file-excel fs-1 ms-3"></i
                                ></a>
                            </td>
                        </tr>
                        <!-- SUD EST -->
                        <tr>
                            <td>SUD EST</td>
                            <td>Région</td>
                            <td class="text-end">
                                <a target="_blank" href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_SUD_EST.pptx"
                                    ><i class="fas fa-file-powerpoint fs-1"></i
                                ></a>
                                <a target="_blank" href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_SUD_EST.xlsx"
                                    ><i class="fas fa-file-excel fs-1 ms-3"></i
                                ></a>
                            </td>
                        </tr>

                        <!-- SUD OUEST -->
                        <tr>
                            <td>SUD OUEST</td>
                            <td>Région</td>
                            <td class="text-end">
                                <a target="_blank" href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_SUD_OUEST.pptx"
                                    ><i class="fas fa-file-powerpoint fs-1"></i
                                ></a>
                                <a target="_blank" href="/media/hzB7B7Sue3Fovkdu9jhwEFQl62iNytN8l6hEzEnQGuH1gemEzJ1MnxSpoSyCeFSr/Rapport_RTE&Moi_2023_SUD_OUEST.xlsx"
                                    ><i class="fas fa-file-excel fs-1 ms-3"></i
                                ></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div v-if="loading">
                    <div class="d-flex row-auto flex-center w-100 h-200px">
                        <span class="spinner-border text-primary" role="status"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import XLSX from "xlsx";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import userApi from "@/core/services/User";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRouter } from "vue-router";
import buttonHandle from "@/shared.js";

export default defineComponent({
    name: "users",
    components: {},
    data: function () {
        return {
            users: [],
            filter: {
                identity: "",
                email: "",
                user_type: "",
                status: "",
            },
            userFileData: [],
            loading: false,
        };
    },
    methods: {},
    setup() {
        const { t } = useI18n();
        const router = useRouter();
        const submitButton = ref<HTMLElement | null>(null);
        return { t, submitButton };
    },

    created() {
        setCurrentPageBreadcrumbs("Rapports spécifiques", []);
    },
});
</script>
