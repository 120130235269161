
import { defineComponent, ref } from "vue";
import XLSX from "xlsx";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import userApi from "@/core/services/User";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRouter } from "vue-router";
import buttonHandle from "@/shared.js";

export default defineComponent({
    name: "users",
    components: {},
    data: function () {
        return {
            users: [],
            filter: {
                identity: "",
                email: "",
                user_type: "",
                status: "",
            },
            userFileData: [],
            loading: false,
        };
    },
    methods: {},
    setup() {
        const { t } = useI18n();
        const router = useRouter();
        const submitButton = ref<HTMLElement | null>(null);
        return { t, submitButton };
    },

    created() {
        setCurrentPageBreadcrumbs("Rapports spécifiques", []);
    },
});
